@font-face {
  font-family: "inter400";
  src: url("../fonts/Inter/Inter-Regular.ttf");
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "inter500";
  src: url("../fonts/Inter/Inter-Medium.ttf");
  src: url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "inter700";
  src: url("../fonts/Inter/Inter-Bold.ttf");
  src: url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "poppins400";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
  src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "poppins500";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "poppins600";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "poppins700";
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
  src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto400";
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
