@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?ry2zfe");
  src: url("../fonts/icomoon.eot?ry2zfe#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?ry2zfe") format("truetype"),
    url("../fonts/icomoon.woff?ry2zfe") format("woff"),
    url("../fonts/icomoon.svg?ry2zfe#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

i.mltColorIcon {
  font-size: 20px;
}

.icon-sending-mail .path1:before {
  content: "\e926";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-sending-mail .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-sending-mail-blue .path1:before {
  content: "\e928";
  color: rgb(63, 115, 246);
  opacity: 0.3;
}
.icon-sending-mail-blue .path2:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(63, 115, 246);
}
.icon-sending-mail-gray .path1:before {
  content: "\e92a";
  color: rgb(0, 0, 0);
  opacity: 0.3;
}
.icon-sending-mail-gray .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(153, 161, 170);
}
.icon-lock:before {
  content: "\e912";
}
.icon-user .path1:before {
  content: "\e939";
  color: rgb(41, 41, 48);
  opacity: 0.3;
}
.icon-user .path2:before {
  content: "\e93a";
  margin-left: -0.9599609375em;
  color: rgb(41, 41, 48);
}
.icon-user-blue .path1:before {
  content: "\e93b";
  color: rgb(63, 115, 246);
  opacity: 0.3;
}
.icon-user-blue .path2:before {
  content: "\e93c";
  margin-left: -0.9599609375em;
  color: rgb(63, 115, 246);
}
.icon-user-square:before {
  content: "\e93d";
}
.icon-message .path1:before {
  content: "\e917";
  color: rgb(39, 39, 46);
  opacity: 0.3;
}
.icon-message .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(39, 39, 46);
}
.icon-message-blue .path1:before {
  content: "\e919";
  color: rgb(63, 115, 246);
  opacity: 0.3;
}
.icon-message-blue .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(63, 115, 246);
}
.icon-tick:before {
  content: "\e932";
}
.icon-tick-circle:before {
  content: "\e933";
}

.icon-cross:before {
  content: "\e90b";
}
